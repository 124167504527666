import { signInWithEmailAndPassword } from "firebase/auth"
import { useState } from "react"
import { auth } from "../config/firebaseConfig"

const LoginPage = ({ setLoggedIn }) => {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [loading, setLoading] = useState(false)

	const handleLogin = () => {
		if(password) {
			setLoading(true)
			signInWithEmailAndPassword(auth, email, password)
			.then((user) => {
				if(user) {
					setLoggedIn(true)
					setLoading(false)
				}
			})
			.catch((err) => {
				setLoading(false)
			})
		}
	}

	return (
		<div className="grid place-items-center h-screen w-[80%] md:w-full lg:w-full">
			<div className="flex flex-col w-[600px] bg-white p-[50px] rounded-2xl shadow-2xl shadow-gray-200">
				<p className="mb-4 font-medium text-[36px]">Login to your account</p>
				<div className="flex flex-col mb-4">
					<label htmlFor="email">Email (required)</label>
					<input name="email" id="email" className="input-field" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
				</div>
				<div className="flex flex-col mb-4">
					<label htmlFor="password">Password (required)</label>
					<input name="password" id="password" className="input-field" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
				</div>
				<button className="mt-6 ml-auto mr-auto styled-button text-[18px]" onClick={handleLogin} disabled={loading}>{!loading ? "Login" : "Logging in"}</button>
			</div>
		</div>
	)
}

export default LoginPage