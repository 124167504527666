import React from 'react'
import { getAppLogo } from '../utils/helpers'

const Header = ({ setCurrentPage }) => {
	return (
		<div className="fixed top-0 w-full flex justify-between items-center p-6 shadow-2xl shadow-slate-200 bg-themeBg z-[999] cursor-pointer">
			<img className="w-fit h-fit" src={getAppLogo()} alt="logo" onClick={() => setCurrentPage('home')}/>
			<p className="rounded-2xl p-[30px] pt-[15px] pb-[15px] bg-theme text-white text-xl font-bold hover:bg-opacity-[90%]" onClick={() => setCurrentPage('make')}>Get Started</p>
		</div>
	)
}

export default Header