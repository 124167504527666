import USBGLogo from '../assets/logos/usbg-logo.png'
import EZBusinessPlan from '../assets/logos/ez-business-plan-logo.svg'
import GrantApprovedBusinessPlans from '../assets/logos/grant-approved-business-plans-logo.svg'
import LaunchBusinessPlan from '../assets/logos/launch-business-plan-logo.svg'

export const getAppName = () => {
	switch(window.location.hostname) {
		case 'localhost':
			return 'US Business Grants'
	}
}

export const getAppLogo = () => {
	switch(window.location.hostname) {
		case 'localhost':
			return (USBGLogo)
		case 'usbusinessgrants.org':
			return (USBGLogo)
		case 'usbg-business-plan-generator.web.app':
			return (USBGLogo)
		case 'ez-businessplan.com':
			return (EZBusinessPlan)
		case 'grantapprovedbusinessplans.com':
			return (GrantApprovedBusinessPlans)
		case 'launchbusinessplan.com':
			return (LaunchBusinessPlan)
	}
}