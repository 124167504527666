import { useEffect, useState } from "react";
import LoginPage from "./components/Login";
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './config/firebaseConfig';
import Form from './components/Form';
import { Route, Router } from 'wouter';
import Header from "./components/Header";
import Home from "./pages/Home";

const App = () => {
  
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  const [currentPage, setCurrentPage] = useState('home')

  const appTitle = "Business Plan Generator"

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setLoading(true)
      if(user) {
        setLoggedIn(true)
        setLoading(false)
      } else {
        setLoggedIn(false)
        setLoading(false)
      }
    })
  }, [])

  const getCurrentPage = () => {
    switch(currentPage) {
      case 'home':
        return <Home setCurrentPage={setCurrentPage}/>
      case 'login':
        return <LoginPage setCurrentPage={setCurrentPage}/>
      case 'make':
        return <Form setCurrentPage={setCurrentPage}/>
      default:
        return <Home setCurrentPage={setCurrentPage}/>
    }
  }

  return (
    <div className="app w-full flex flex-col bg-themeBg">
      <Header setCurrentPage={setCurrentPage}/>
      <div>
        {getCurrentPage()}
        {/* <Router>
          <Route path="/business-plan" component={Home} />
          <Route path="/business-plan/login" component={LoginPage} />
          <Route path="/business-plan/make" component={Form} />
        </Router> */}
      </div>
    </div>
  );
}

export default App;
