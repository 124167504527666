import React from 'react'

const Home = ({ setCurrentPage }) => {
	return (
		<div>
			<div className="overflow mt-[30px]">
				<div className="section-hero wf-section">
						<div className="content">
							<div className="block-hero">
									{/* <div className="flex justify-center items-center gap-5 mb-5"><button className="text-sm underline">change language</button></div> */}
									<h1 className="heading-hero text-theme">Make a Business Plan in <br /> 15 Minutes</h1>
									<p className="text-xl">Use it to get a grant and/or other funding for your business.</p>
									<div className="mt-[30px] flex flex-col">
										<p className="styled-button w-full" onClick={() => setCurrentPage('make')}>Get Your Business Plan</p><br/>
										<div className="flex items-center justify-between mb-8">
												<div className="w-1/2 border-b border-gray-300"></div>
												<div className="text-gray-600 px-4">or</div>
												<div className="w-1/2 border-b border-gray-300"></div>
										</div>
										<a className="styled-button-outlined w-button" href="/login">Login if you've already made a plan</a>
										<div className="relative">
												{/* <div><img alt="" src="/_next/image?url=%2Fimg%2Fblurred_1.png&amp;w=750&amp;q=75" width="342" height="279" decoding="async" data-nimg="1" className="blurred-red---hero" loading="lazy" style={{ color: 'transparent' }} /></div> */}
												{/* <div><img alt="" src="/_next/image?url=%2Fimg%2Fblurred_2.png&amp;w=750&amp;q=75" width="342" height="279" decoding="async" data-nimg="1" className="blurred-blue---hero" loading="lazy" style={{ color: 'transparent' }}  /></div> */}
										</div>
									</div>
							</div>
						</div>
				</div>
				<div className="section wf-section">
						<div className="content">
							<div className="block-heading">
									<h2 className=" heading">Who is this for?</h2>
							</div>
							<div className="w-layout-grid grid-3-columns">
									<div style={{ opacity: 1 }}>
										<div className="sequence hover:shadow-lg hover:shadow-gray-200 transition-shadow">
												<div className="icon-sequence-bg"><img alt="" src={require("../assets/icons/attach_money.svg").default} width="500" height="500" decoding="async" data-nimg="1" className="icon-sequence" loading="lazy" style={{ color: 'transparent' }}  /></div>
												<h6 className="heading-sequence">Entrepreneurs looking for investor funding</h6>
												<p className="text">Our business plan generator helps entrepreneurs in creating professional business plans designed to impress potential investors and secure funding.</p>
										</div>
									</div>
									<div style={{ opacity: 1 }}>
										<div className=" sequence hover:shadow-lg hover:shadow-gray-200 transition-shadow">
												<div className="icon-sequence-bg"><img alt="" src={require("../assets/icons/account_balance.svg").default} width="500" height="500" decoding="async" data-nimg="1" className="icon-sequence" loading="lazy" style={{ color: 'transparent' }}  /></div>
												<h6 className="heading-sequence">Business owners and entreprenuers looking for a bank loan</h6>
												<p>Our business plan generator is SBA-approved and follows the standard format required by grant-giving organizations and U.S. banks.</p>
										</div>
									</div>
									<div style={{ opacity: 1 }}>
										<div className=" sequence hover:shadow-lg hover:shadow-gray-200 transition-shadow">
												<div className="icon-sequence-bg"><img alt="" src={require("../assets/icons/person.svg").default} width="500" height="500" decoding="async" data-nimg="1" className="icon-sequence" loading="lazy" style={{ color: 'transparent' }}  /></div>
												<h6 className="heading-sequence">For anyone looking to make a business plan</h6>
												<p>Whether you want a business plan for self-learning, as a reference for a project, or for submission to a non-financial institution, we've got you covered.</p>
										</div>
									</div>
							</div>
						</div>
				</div>
				{/* <div className="section wf-section">
						<div className="content">
							<div className="block-heading">
									<h2 className=" heading">What customers say about us</h2>
									<p className="text-xl">Our ultimate goal is to make a product that <strong>you</strong> love, to do that we need feedback. So we try to talk to as many customers as we can</p>
							</div>
							<div className="w-layout-grid grid-3-columns">
									<div className="sequence shadow-on-hover">
										<p className="text-2xl"><strong>Zede H.</strong></p>
										<img alt="" srcset="/_next/image?url=%2Fimg%2Fzede1.png&amp;w=640&amp;q=100 1x, /_next/image?url=%2Fimg%2Fzede1.png&amp;w=1080&amp;q=100 2x" src="/_next/image?url=%2Fimg%2Fzede1.png&amp;w=1080&amp;q=100" width="500" height="500" decoding="async" data-nimg="1" loading="lazy" style={{ color: 'transparent' }}  /><br/><img alt="" srcset="/_next/image?url=%2Fimg%2Fzede2.png&amp;w=640&amp;q=100 1x, /_next/image?url=%2Fimg%2Fzede2.png&amp;w=1080&amp;q=100 2x" src="/_next/image?url=%2Fimg%2Fzede2.png&amp;w=1080&amp;q=100" width="500" height="500" decoding="async" data-nimg="1" loading="lazy" style={{ color: 'transparent' }}  />
									</div>
									<div className="sequence shadow-on-hover">
										<p className="text-2xl"><strong>Jason C.</strong></p>
										<img alt="" srcset="/_next/image?url=%2Fimg%2Fjason2.png&amp;w=640&amp;q=100 1x, /_next/image?url=%2Fimg%2Fjason2.png&amp;w=1080&amp;q=100 2x" src="/_next/image?url=%2Fimg%2Fjason2.png&amp;w=1080&amp;q=100" width="500" height="500" decoding="async" data-nimg="1" loading="lazy" style={{ color: 'transparent' }}  /><br/><img alt="" srcset="/_next/image?url=%2Fimg%2Fjason1.png&amp;w=640&amp;q=100 1x, /_next/image?url=%2Fimg%2Fjason1.png&amp;w=1080&amp;q=100 2x" src="/_next/image?url=%2Fimg%2Fjason1.png&amp;w=1080&amp;q=100" width="500" height="500" decoding="async" data-nimg="1" loading="lazy" style={{ color: 'transparent' }}  />
									</div>
									<div className="sequence shadow-on-hover">
										<p className="text-2xl"><strong>Parker A.</strong></p>
										<img alt="" srcset="/_next/image?url=%2Fimg%2Fparker1.png&amp;w=640&amp;q=100 1x, /_next/image?url=%2Fimg%2Fparker1.png&amp;w=1080&amp;q=100 2x" src="/_next/image?url=%2Fimg%2Fparker1.png&amp;w=1080&amp;q=100" width="500" height="500" decoding="async" data-nimg="1" loading="lazy" style={{ color: 'transparent' }}  /><br/><img alt="" srcset="/_next/image?url=%2Fimg%2Fparker2.png&amp;w=640&amp;q=100 1x, /_next/image?url=%2Fimg%2Fparker2.png&amp;w=1080&amp;q=100 2x" src="/_next/image?url=%2Fimg%2Fparker2.png&amp;w=1080&amp;q=100" width="500" height="500" decoding="async" data-nimg="1" loading="lazy" style={{ color: 'transparent' }}  />
									</div>
							</div>
						</div>
				</div> */}
				<div className="section wf-section">
						<div className="content">
							<div className="block-heading">
									<h2 className="heading">How it works?</h2>
									<p className="text-xl">Our business plan generator guides you through a few questions. Once completed, a business plan will be generated. This usually takes less than 15 minutes.</p>
							</div>
							<div className="mt-[50px] w-layout-grid grid-2-columns">
									<div style={{ opacity: 1 }}>
										<div className="sequence hover:shadow-lg hover:shadow-gray-200 transition-shadow">
												<div className="icon-sequence-bg">
													<img alt="" src={require("../assets/icons/fact_check.svg").default} width="40" height="40" decoding="async" data-nimg="1" className="icon-sequence" loading="lazy" style={{ color: 'transparent' }}  />
													<div className="number-sequence-bg">
															<div className="number-sequence">1</div>
													</div>
												</div>
												<h6 className="heading-sequence">Enter General Business Information</h6>
												<p>Only basic business information is required e.g. business name, number of employees, and a little financial data.</p>
										</div>
									</div>
									<div style={{ opacity: 1 }}>
										<div className="sequence hover:shadow-lg hover:shadow-gray-200 transition-shadow">
												<div className="icon-sequence-bg">
													<img alt="" src={require("../assets/icons/edit.svg").default} width="40" height="40" decoding="async" data-nimg="1" className="icon-sequence" loading="lazy" style={{ color: 'transparent' }}  />
													<div className="number-sequence-bg">
															<div className="number-sequence">2</div>
													</div>
												</div>
												<h6 className="heading-sequence">Edit and Save</h6>
												<p>Once your business plan is generated you can easily edit the plan at a later time to fit the needs and changes to your business.</p>
										</div>
									</div>
							</div>
						</div>
				</div>
				{/* <div className="section wf-section">
						<div className="content">
							<div className="block-heading">
									<h2 className=" heading">Key Benefits</h2>
							</div>
							<div className="w-layout-grid grid-2-columns">
									<div style={{ opacity: 0 }}>
										<div className="feature hover:shadow-lg hover:shadow-gray-200 transition-shadow-red">
												<div className="block-heading-feature">
													<img alt="" src="../assets/icons/language.svg" width="500" height="500" decoding="async" data-nimg="1" className="icon-feature" loading="lazy" style={{ color: 'transparent' }}  />
													<h5 className="heading-feature">Our AI draws business knowledge from the entire internet</h5>
												</div>
												<p>GPT-3.5 and GPT-4 are our cutting-edge language models, trained on vast quantities of internet, book-based resources, business plan examples, and has demonstrated its knowledge by <strong>passing the Wharton MBA exam</strong> among many others</p>
												<img alt="" sizes="100vw" srcset="/_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=640&amp;q=75 640w, /_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=750&amp;q=75 750w, /_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=828&amp;q=75 828w, /_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=1080&amp;q=75 1080w, /_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=1200&amp;q=75 1200w, /_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=1920&amp;q=75 1920w, /_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=2048&amp;q=75 2048w, /_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=3840&amp;q=75 3840w" src="/_next/image?url=%2Fimg%2Fbenefit1V2.png&amp;w=3840&amp;q=75" width="960" height="687" decoding="async" data-nimg="1" className="image" loading="lazy" style={{ color: 'transparent', width: '100%', height: 'auto' }} />
										</div>
									</div>
									<div style={{ opacity: 0 }}>
										<div className="feature hover:shadow-lg hover:shadow-gray-200 transition-shadow-blue">
												<div className="block-heading-feature">
													<img alt="" src="../assets/icons/bolt.svg" width="500" height="500" decoding="async" data-nimg="1" className="icon-feature" loading="lazy" style={{ color: 'transparent' }}  />
													<h5 className="heading-feature">The whole process can literally take 15 minutes</h5>
												</div>
												<p>Gone are the days of laboring over business plans for weeks on end, our AI business plan generator can create a comprehensive plan in just 15 minutes.</p>
												<img alt="" sizes="100vw" srcset="/_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=640&amp;q=75 640w, /_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=750&amp;q=75 750w, /_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=828&amp;q=75 828w, /_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=1080&amp;q=75 1080w, /_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=1200&amp;q=75 1200w, /_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=1920&amp;q=75 1920w, /_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=2048&amp;q=75 2048w, /_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=3840&amp;q=75 3840w" src="/_next/image?url=%2Fimg%2Fbenefit2.png&amp;w=3840&amp;q=75" width="433" height="160" decoding="async" data-nimg="1" className="image-feature" loading="lazy" style={{ color: 'transparent', width: '100%', height: 'auto' }} />
										</div>
									</div>
							</div>
						</div>
				</div> */}
				<div className="section wf-section">
						<div className="block-right">
							<h2 className="heading">Our business plan template won't let you down</h2>
							<p className="paragraph-large">create a professional business plan in just 15 minutes with our business plan generator</p>
							<div className="flex flex-col">
									<a className="styled-button w-button" href="/make">Get Your Business Plan</a><br/>
									<div className="flex items-center justify-between mb-8">
										<div className="w-1/2 border-b border-gray-300"></div>
										<div className="text-gray-600 px-4">or</div>
										<div className="w-1/2 border-b border-gray-300"></div>
									</div>
									<a className="styled-button-outlined w-button" href="/login">Login if you've already made a plan</a>
							</div>
						</div>
						<div className="content">
							<div className="w-layout-grid grid-2-columns single">
									<div className="blurred-grid">
										{/* <div className="blurred"><img alt="" srcset="/_next/image?url=%2Fimg%2Fblurred_1.png&amp;w=828&amp;q=75 1x, /_next/image?url=%2Fimg%2Fblurred_1.png&amp;w=1920&amp;q=75 2x" src="/_next/image?url=%2Fimg%2Fblurred_1.png&amp;w=1920&amp;q=75" width="798" height="651" decoding="async" data-nimg="1" className="blurred-red---grid" loading="lazy" style={{ color: 'transparent' }}  /><img alt="" srcset="/_next/image?url=%2Fimg%2Fblurred_2.png&amp;w=828&amp;q=75 1x, /_next/image?url=%2Fimg%2Fblurred_2.png&amp;w=1920&amp;q=75 2x" src="/_next/image?url=%2Fimg%2Fblurred_2.png&amp;w=1920&amp;q=75" width="798" height="651" decoding="async" data-nimg="1" className="blurred-blue---grid" loading="lazy" style={{ color: 'transparent' }}  /></div> */}
									</div>
							</div>
						</div>
				</div>
				{/* <div className="section-footer wf-section">
						<div className="content">
							<div className="w-full">
									<div id="w-node-_2b89f38d-417d-b761-09ed-84893bf9cd2d-3bf9cd2a" className="block-footer">
										<img alt="" src={require("../assets/logo.png")} decoding="async" data-nimg="1" className="logo-footer" loading="lazy" style={{ color: 'transparent' }}  />
										<a href="mailto:help@usbusinessgrants.org" className="icon-link-footer w-inline-block">
												<div className="text-footer">Contact us at: help@usbusinessgrants.org</div>
										</a>
									</div>
							</div>
							<div className="footer-down">
									<div className="text-footer-down">©2023 usbusinessgrants.org All rights reserved.</div>
							</div>
						</div>
				</div> */}
			</div>
		</div>
	)
}

export default Home